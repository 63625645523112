.custom-basic-layout {
    /* background-image: url("assets/images/bg.jpg"); */
    background-position: left;
    background-size: cover;
    background-repeat: no-repeat;
    
  }
  
  .form-container {
   position: absolute;
   left: 50%;
   margin: 0 auto;
   height: 100VH;
   width: 210%;
  }

  .img-flui{
    width: 10%;
    height: 60%;
    display: block;
    margin: 0px auto;
    margin-top: 100px;
  }

 

  h2{
    text-align: center;
    position: relative;
    top: 10%;
    color: #0D8235;
  }

  .imgff{
    position: relative;
    width: 70%;
    margin: 0 auto;
  }
 
  
  .logoSignin-row {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .logoSignin {
    width: 50%;
    height: auto;
  }
 
  
  .logoSigninClient {
    width: 80%;
    height: auto; /* Maintain aspect ratio */
  }
 